<script setup>
import { inject, watch, computed, ref } from 'vue';
import { useCartFunctions } from '@/composables/useCartFunctions';

import { activeDesktopFlyoutKey, isSearchOpenKey, emitterKey } from '../../keys';

const headerState = inject('headerState');
const menuContent = inject('$mainMenuReactive');
const { isCartOpen } = useCartFunctions();
const transitionSpeed = headerState.speed;

let timer;
const activeDesktopFlyout = inject(activeDesktopFlyoutKey);
const isDesktopFlyoutOpen = computed(() => activeDesktopFlyout.uid !== null);
const mitt = inject(emitterKey);
const isSearchOpen = inject(isSearchOpenKey);
const mainMenuRef = ref(null);
const lastOpened = ref(null);

function closeOtherOpenHeaderItems() {
  mitt.emit('close-search');
  mitt.emit('clear-search-input');
  isCartOpen.value = false;
}

function clearSection() {
  activeDesktopFlyout.uid = null;
}

function toggleSection(item) {
  closeOtherOpenHeaderItems();
  if (activeDesktopFlyout.uid === item.id) {
    if (activeDesktopFlyout.isPinned) {
      activeDesktopFlyout.uid = null;
    } else {
      activeDesktopFlyout.isPinned = true;
    }
  } else {
    activeDesktopFlyout.uid = item.id;
    activeDesktopFlyout.isPinned = true;
    clearTimeout(timer);
  }
}

function startTimer(item) {
  if (activeDesktopFlyout.uid) {
    // Something's already open, switch faster
    timer = setTimeout(() => {
      closeOtherOpenHeaderItems();
      activeDesktopFlyout.uid = item.id;
    }, 300);
    return;
  }

  timer = setTimeout(() => {
    closeOtherOpenHeaderItems();
    activeDesktopFlyout.uid = item.id;
    activeDesktopFlyout.isPinned = false;
  }, 500);
}

function stopTimer() {
  if (timer) {
    clearTimeout(timer);
  }
}

function closeHeaderAndHeaderOpenItems() {
  closeOtherOpenHeaderItems();
  activeDesktopFlyout.uid = null;
  activeDesktopFlyout.isPinned = false;
}

watch(isDesktopFlyoutOpen, (val) => {
  if (!val && mainMenuRef.value) {
    const buttonToFocus = mainMenuRef.value.find(
      (button) => button?.dataset?.menuId === lastOpened.value,
    );
    // eslint-disable-next-line no-unused-expressions
    buttonToFocus?.focus();
  } else {
    lastOpened.value = activeDesktopFlyout.uid;
  }
});

watch(
  () => isSearchOpen.value,
  () => {
    if (isSearchOpen.value) {
      stopTimer();
      clearSection();
    }
  },
);

watch(
  () => isCartOpen.value,
  (isOpen) => {
    if (isOpen) {
      stopTimer();
      clearSection();
    }
  },
);

</script>

<template>
  <ul class="desktop-links">
    <li
      class="desktop-links__link-item"
      v-for="item in menuContent"
      :key="item.id"
    >
      <router-link
        v-if="item.type === 'MenuItem'"
        @mouseenter="closeHeaderAndHeaderOpenItems()"
        :to="item.url"
        class="desktop-links__link"
        :style="{transition:`color ${transitionSpeed} ease`}"
      >
        {{ item.label }}
      </router-link>
      <button
        v-if="item.type === 'MenuMain'"
        @mouseenter="startTimer(item)"
        @mouseleave="stopTimer()"
        @click.prevent="toggleSection(item)"
        :data-menu-id="item.id"
        ref="mainMenuRef"
        class="desktop-links__link clean-button"
        :class="{
          'desktop-links__link--open': activeDesktopFlyout.uid === item.id,
        }"
        :style="{transition:`color ${transitionSpeed} ease`}"
      >
        {{ item.label }}
      </button>
    </li>
  </ul>
</template>

<style>
.desktop-links {
  margin: 0 20px 0 57px;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}

@media (max-width: 1280px) {
  .desktop-links {
    margin: 0 20px 0 25px;
  }
}

.desktop-links__link-item .desktop-links__link,
.desktop-links__link:hover,
.desktop-links__link:active,
.desktop-links__link:visited {
  height: 40px;
  line-height: 20px;
  padding: 0 10px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.desktop-links__link:hover,
.desktop-links__link:active,
.desktop-links__link:focus,
.desktop-links__link:hover .desktop-links__link,
.desktop-links__link:active .desktop-links__link,
.desktop-links__link:focus .desktop-links__link {
  color: currentColor;
}

.desktop-links__link:hover:after,
.desktop-links__link--open:after {
  content: '';
  position: absolute;
  left: 10.5px;
  right: 10.5px;
  bottom: 10px;
  border-bottom: 1px solid var(--color-black);
}

.desktop-links__link-item:last-child .desktop-links__link {
  padding-right: 0;
}
</style>
