<script setup>
import { inject, computed, onMounted, onBeforeUnmount, ref } from 'vue';
import { cartKey } from '@/keys';
import CountrySwitcher from '../CountrySwitcher.vue';
import { useCartFunctions } from '@/composables/useCartFunctions';
import useContext from '@/composables/useContext';

const { mitt } = useContext();
const { isCartOpen } = useCartFunctions();
const headerState = inject('headerState');
const cart = inject(cartKey, { value: {} });
const searchButton = ref(null);

const isQuickCardEnabled = computed(() => cart?.value?.ecommerceFeaturesEnabled || false);
const invertColor = computed(() => (headerState.theme === 'white' && !headerState.scrolled ? 1 : 0));
const strokeColor = computed(() => (invertColor.value ? 'white' : 'black'));
const cartCount = computed(() => (cart.value?.rows || []).reduce((acc, c) => acc + c.quantity, 0));
const transitionSpeed = headerState.speed;

function openQuickCart() {
  isCartOpen.value = !isCartOpen.value;
}

function focusSearchButton() {
  searchButton.value.focus();
}

onMounted(() => {
  mitt.on('close-search-input', focusSearchButton);
});

onBeforeUnmount(() => {
  mitt.off('close-search-input', focusSearchButton);
});
</script>

<template>
  <div class="header-actions">
    <button
      class="header-actions__item clean-button"
      ref="searchButton"
      @click="$mitt.emit('open-search-view')"
    >
      <div
        class="header-actions__item-icon"
        :class="`header-actions__item-icon--inverted-${invertColor}`"
        :style="{transition:`filter ${transitionSpeed} ease`}"
      >
        <img
          class="header-actions__search-icon"
          src="../../static/icons/search_icon.svg"
        />
      </div>
    </button>
    <button
      class="header-actions__item clean-button"
      @click="openQuickCart"
      v-if="isQuickCardEnabled"
    >
      <div
        class="header-actions__item-icon"
        :class="`header-actions__item-icon--inverted-${invertColor}`"
        :style="{transition:`filter ${transitionSpeed} ease`}"
      >
        <svg
          width="18"
          height="21"
          viewBox="0 0 18 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.625"
            y="7.14258"
            width="16.75"
            height="12.8374"
            stroke="black"
            stroke-width="1.25"
          />
          <path
            d="M14.1404 9.71939V6.51771C14.1404 3.68852 11.8378 1.395 8.9975 1.395C6.15718 1.395 3.85464 3.68852 3.85464 6.51771V9.71939"
            stroke="black"
            stroke-width="1.25"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <div class="header-actions__cart-count"
        :style="{transition:`color ${transitionSpeed} ease`, color:strokeColor}"
      >
        {{ cartCount }}
      </div>
    </button>
    <CountrySwitcher
      class="header-actions__item desktop-only header-actions__country-switcher"
      :style="{transition:`color ${transitionSpeed} ease`, color:strokeColor}"
    />
  </div>
</template>

<style>
.header-actions {
  display: flex;
  padding-bottom: 5px;

  @media (min-width: 840px) {
    margin-left: auto;
    padding-right: 15px;
  }
}

.clean-button.header-actions__item {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.header-actions__item:nth-last-child(2) {
  margin-right: 20px;
}

.header-actions__item:last-child {
  margin-right: 0px;
}

.header-actions__search-icon {
  height: 22px;
  padding-top: 2px;
  filter: brightness(0%);
  min-width: 23px;
}

.header-actions__item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 839px) {
  .header:not(.header--visible) .header-actions__item-icon--inverted-1 {
    filter: invert(1);
  }

  .header-actions__item.desktop-only {
    display: none;
  }

  .header-actions__item:nth-last-child(2) {
    margin-right: 0px;
  }
}

@media (min-width: 840px) {
  .header:not(:hover):not(.header--visible) .header-actions__item-icon--inverted-1 {
    filter: invert(1);
  }
}

.header-actions__cart-count {
  height: 18px;
  line-height: 24px;
  font-size: 16px;
  margin-left: 5px;
}

.header:hover .header-actions__cart-count,
.header.header--visible .header-actions__cart-count,
.header:hover .header-actions__country-switcher,
.header.header--visible .header-actions__country-switcher {
  color: inherit !important;
}

.header-actions__item-label {
  @media (max-width: 767px) {
    display: none;
  }
}
</style>
