<script setup>
import {
  inject, computed, ref, onMounted, onBeforeUnmount,
} from 'vue';
import { toLitiumMediaUrlKey } from '@distancify/drapejs-litium/lib/keys';
import { activeDesktopFlyoutKey, isSearchOpenKey, channelKey } from '@/keys';
import HamburgerMenu from './HamburgerMenu.vue';
import DesktopLinks from './DesktopLinks.vue';
import DesktopFlyout from './DesktopFlyout.vue';
import SearchView from '../SearchView/SearchView.vue';
import HeaderActions from './HeaderActions.vue';
import QuickCart from '../QuickCart/QuickCart.vue';
import { useCartFunctions } from '@/composables/useCartFunctions';
import useContext from '@/composables/useContext';

const { mitt } = useContext();
const channel = inject(channelKey);
const activeDesktopFlyout = inject(activeDesktopFlyoutKey);
const isSearchOpen = inject(isSearchOpenKey);
const headerState = inject('headerState');
const transitionSpeed = headerState.speed;
const menuContent = inject('$mainMenuReactive');
const toLitiumMediaUrl = inject(toLitiumMediaUrlKey);

const logoUrl = computed(() => {
  const logo = channel.value?.frameworkContentJson?.CompanyLogo;
  return toLitiumMediaUrl(logo?.id, { maxHeight: 27 }) || '';
});

const logoAlt = computed(() => {
  const logo = channel.value?.frameworkContentJson?.CompanyLogo;
  return logo?.alt;
});

const { isCartOpen } = useCartFunctions();
const isGeoOpen = ref(false);

function updateGeo(bool) {
  isGeoOpen.value = bool;
}

onMounted(() => {
  mitt.on('geo-ip-popup', updateGeo);
});

onBeforeUnmount(() => {
  mitt.off('geo-ip-popup', updateGeo);
});
</script>

<template>
  <div
    :class="[
      `header header--${headerState.theme}`,
      {
        'header--desktop-flyout': activeDesktopFlyout.uid,
        'header--visible':
          isCartOpen ||
          isSearchOpen ||
          headerState.scrolled ||
          activeDesktopFlyout.uid ||
          isGeoOpen,
      },
    ]"
    :style="{transition:`background-color ${transitionSpeed} ease`}"
    @click.stop
  >
    <div class="header__margin">
      <HamburgerMenu class="header__menu" />
      <router-link :to="channel?.rootPath">
        <img
          class="header__logo"
          :src="logoUrl"
          :alt="logoAlt"
          :style="{transition:`filter ${transitionSpeed} ease`}"
        />
      </router-link>
      <DesktopLinks
        class="header__desktop-links"
        :style="{transition:`color ${transitionSpeed} ease`}"
      />
      <HeaderActions class="header__actions" />
    </div>
  </div>
  <QuickCart></QuickCart>
  <template v-for="item in menuContent" :key="item.id">
    <DesktopFlyout class="header__flyout" v-if="item.type === 'MenuMain'" :item="item" />
  </template>
  <SearchView />
</template>

<style>
.header {
  background-color: var(--color-neutral-00);
  position: relative;
  z-index: 10;
  padding: 0 15px;
}

.header--desktop-flyout {
  border-bottom: 1px solid var(--color-grey700);
}

@media (max-width: 839px) {
  .header--white:not(.header--visible) {
    background-color: transparent;
    color: var(--color-neutral-00);
  }

  .header--white:not(.header--visible) .header__logo {
    filter: invert(1);
  }

  .header--black:not(.header--visible) {
    background-color: transparent;
  }
}

@media (min-width: 840px) {
  .header--white:not(:hover):not(.header--visible) {
    background-color: transparent;
    color: var(--color-neutral-00);
  }

  .header--white:not(:hover):not(.header--visible) .header__logo {
    filter: invert(1);
  }

  .header--black:not(:hover):not(.header--visible) {
    background-color: transparent;
  }

  .header--white:not(:hover):not(.header--visible) .desktop-links__link {
    color: var(--color-neutral-00);
  }

  .header--white:hover .desktop-links__link {
    color: #000;
  }
}

.header__margin {
  max-width: var(--layout-maxWidth);
  margin: auto;
  display: flex;
  align-items: center;
  height: 66px;

  @media (max-width: 839px) {
    justify-content: space-between;
    height: 47px;
  }
}

.header__menu,
.header__actions {
  @media (max-width: 839px) {
    flex: 1;
  }
}

.header__actions {
  @media (max-width: 839px) {
    justify-content: flex-end;
  }
}

.header__logo {
  display: block;
  object-fit: contain;
  object-position: center left;

  @media (max-width: 839px) {
    margin: 8px 10px;
    height: 25px;
  }
  @media (min-width: 840px) {
    margin: 8px 0 8px 15px;
    height: 27px;
  }
}

@media (--tabletAndDesktop) {
  .header {
    padding: 0px 45px;
  }
}

.header--white:not(.header--visible):not(:hover) .header__desktop-links {
  color: var(--color-neutral-00);
}

.header__desktop-links,
.header__flyout {
  @media (max-width: 839px) {
    display: none;
  }
}
</style>
