import { ref, onMounted, onUnmounted } from 'vue';

const registeredKeys = new Set<string>();
const isPageScrolling = ref(false);
let timeoutId: ReturnType<typeof setTimeout> | null = null;
let isTouching = false;
let hasStarted = false;

function handleScroll() {
  isPageScrolling.value = true;
  if (hasStarted) {
    isTouching = true;
  }
  if (timeoutId) clearTimeout(timeoutId);

  timeoutId = setTimeout(() => {
    if (!isTouching) {
      isPageScrolling.value = false;
    }
  }, 50);
}

function handleTouchStart() {
  hasStarted = true;
}

function handleTouchEnd() {
  isTouching = false;

  setTimeout(() => {
    if (!isPageScrolling.value) return;
    isPageScrolling.value = false;
  }, 50);
}

export function useScrollDetector(key: string) {
  onMounted(() => {
    if (!registeredKeys.has(key)) {
      registeredKeys.add(key);
      if (registeredKeys.size === 1) {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchend', handleTouchEnd);
      }
    }
  });

  onUnmounted(() => {
    registeredKeys.delete(key);
    if (registeredKeys.size === 0) {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
    }
  });

  return { isPageScrolling };
}
