<script setup>
import { inject, computed, watch, ref } from 'vue';

import { activeDesktopFlyoutKey } from '@/keys';

import useContext from '@/composables/useContext';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

const props = defineProps({
  item: Object,
});

const { route } = useContext();
const { websiteText } = useWebsiteTexts();

const activeDesktopFlyout = inject(activeDesktopFlyoutKey);

const isClosed = ref(true);
const openFlyout = ref(false);
const linkRefs = ref([]);

const shouldOpen = computed(() => activeDesktopFlyout.uid === props.item.id);
const hasLinks = computed(() => props.item.sections?.length > 0);
const sections = computed(() => {
  return props.item.sections.reduce((acc, s) => {
    if (s && s.id && !s.featured) {
      const itemsWithUrl = s.items.filter(i => i.url);
      acc.push({ ...s, items: itemsWithUrl });
    }

    return acc;
  }, []);
});
const featuredSections = computed(() => {
  return props.item.sections.reduce((acc, s) => {
    if (s && s.id && s.featured) {
      const itemsWithUrl = s.items.filter(i => i.url);
      acc.push({ ...s, items: itemsWithUrl });
    }

    return acc;
  }, []);
});

watch(shouldOpen, (val) => {
  if (!val) {
    openFlyout.value = false;
    updateScroll();
    setTimeout(() => {
      isClosed.value = true;
    }, 150);
  } else {
    isClosed.value = false;
    setTimeout(() => {
      openFlyout.value = true;
      updateScroll();

      if (linkRefs.value.length > 0) {
        linkRefs.value[0].$el.focus();
      }
    }, 0);
  }
});

watch(() => route.pathname, () => {
  if (!activeDesktopFlyout) { return; }
  activeDesktopFlyout.isPinned = false;
  activeDesktopFlyout.uid = null;
});

let timer;
function startTimer() {
  if (!activeDesktopFlyout.isPinned) {
    timer = setTimeout(() => {
      activeDesktopFlyout.uid = null;
    }, 500);
  }
}

function closeDropdown() {
  activeDesktopFlyout.uid = null;
}

function stopTimer() {
  clearTimeout(timer);
}

function updateScroll() {
  if (openFlyout.value) {
    document.body.style.marginRight = `${(
      window.innerWidth - document.body.offsetWidth
    ).toString()}px`;
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.marginRight = '';
    document.body.style.overflow = '';
  }
}
</script>

<template>
  <div class="desktop-flyout" v-if="!isClosed" :class="{'desktop-flyout--open': openFlyout}">
    <div 
      class="desktop-flyout__wrapper"
      @mouseleave="startTimer()"
      @mouseenter="stopTimer()"
      @click.stop
      @keyup.escape="closeDropdown"
    >
      <div class="desktop-flyout__margin">
        <div class="desktop-flyout__featured">
          <ul
            class="desktop-flyout__link-section"
            v-for="section in featuredSections"
            :key="section.id"
          >
            <li class="desktop-flyout__link-section-title">{{section.label}}</li>
            <li class="desktop-flyout__link-item" v-for="item in section.items" :key="item.id">
              <router-link
                v-if="item.type === 'MenuItem'"
                :to="item.url"
                class="desktop-flyout__link"
                ref="linkRefs"
              >
                {{item.label}}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="desktop-flyout__links" v-if="hasLinks">
          <ul class="desktop-flyout__link-section" v-for="section in sections" :key="section.id">
            <li class="desktop-flyout__link-section-title">{{section.label}}</li>
            <li v-if="section.url" class="desktop-flyout__link-item desktop-flyout__link-item--all">
              <router-link
                :to="section.url"
                class="desktop-flyout__link"
              >
              {{ websiteText("navigation__section_all", {section: section.label?.toLowerCase()}).value }}
              </router-link>
            </li>
            <li class="desktop-flyout__link-item" v-for="item in section.items" :key="item.id">
              <router-link
                v-if="item.type === 'MenuItem'"
                :to="item.url"
                class="desktop-flyout__link"
              >
                {{item.label}}
              </router-link>
            </li>
          </ul>
        </div>
        <div v-if="props.item?.blocks?.Promotions" class="desktop-flyout__promotions">
          <blocks-repeater :blocks="props.item.blocks.Promotions" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.desktop-flyout {
  pointer-events: none;
  opacity: 0;
  width: 100%;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 150ms;
}

.desktop-flyout--open {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0);
  height: 100vh;
}

.desktop-flyout:not(.desktop-flyout--open) {
  transition: transform 150ms, opacity 100ms;
}

.desktop-flyout__wrapper {
  background-color: var(--color-neutral-00);
  box-shadow: 0px 5px 16px rgba(26, 26, 26, 0.05);
  padding: 25px 0px;
}

.desktop-flyout__margin {
  max-width: 1470px;
  margin: auto;
  display: flex;
  max-height: calc(100vh - 117px);
  overflow: auto;
  padding-right: 30px;
}

.desktop-flyout__featured {
  width: 220px;
  border-right: 1px solid var(--color-neutral-05);
  flex-shrink: 0;
  display: grid;
  gap: 50px;
  padding-left: 30px;
}

.desktop-flyout__links {
  padding-left: 30px;
  display: grid;
  gap: 50px 90px;
  grid-template-columns: repeat(4, 1fr);

  @media (--tablet) {
    gap: 50px 5vw;
  }
}

.desktop-flyout__link-section {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-width: 200px;
  min-width: 100px;
  flex: 1;
}

.desktop-flyout__link-section-title {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.12em;
  color: var(--color-text-standard);
  text-decoration: none;
  margin-bottom: 10px;
  display: block;
}

.desktop-flyout__link {
  text-decoration: none;
  color: var(--color-text-standard);
  line-height: 30px;
  font-size: 14px;
}

.desktop-flyout__link:hover,
a.desktop-flyout__link-section-title:hover {
  text-decoration: underline;
  color: currentColor;
}

.desktop-flyout__link:visited,
.desktop-flyout__link:active,
.desktop-flyout__link:focus,
a.desktop-flyout__link-section-title:visited,
a.desktop-flyout__link-section-title:active,
a.desktop-flyout__link-section-title:focus {
  color: currentColor;
  text-decoration: none;
}

.desktop-flyout__promotions {
  overflow: hidden;
  flex: 1;
  display: flex;
  padding: 8px 0;
  justify-content: flex-end;
}

.desktop-flyout__promotions > section {
  flex: 1;
  display: flex;
  margin-right: 20px;
  max-width: 300px;
}

.desktop-flyout__promotions > section:last-child {
  margin-right: 0;
}

.desktop-flyout__promotions > section .banner a {
  letter-spacing: 0.12em;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  font-family: var(--font-proxima-nova-light);
  color: var(--color-text-standard);
  text-decoration: none;
}

.desktop-flyout__promotions > section .banner a:hover,
.desktop-flyout__promotions > section .banner a:focus {
  color: var(--color-text-standard);
}

.desktop-flyout__link-item--all {
  text-decoration: underline;
}

.desktop-flyout__link.desktop-flyout__link--special {
  color: var(--color-text-special)
}

.desktop-flyout__link.desktop-flyout__link--sale {
  color: var(--color-sale)
}

.desktop-flyout__link.desktop-flyout__link--bold {
  font-family: var(--font-proxima-nova-bold);
  font-weight: 700;
}
</style>
