<script>
import { computed } from 'vue';
import { useCartFunctions } from '@/composables/useCartFunctions';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import useSizeDisplay from '@/composables/useSizeDisplay';

export default {
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showNumericInput: false,
      inputQuantity: '',
      quantity: this.row.quantity,
    };
  },
  watch: {
    row(val) {
      this.quantity = val.quantity;
    },
  },
  setup(props) {
    const { setQuantityOnRow, isCartOpen } = useCartFunctions();
    const { websiteText } = useWebsiteTexts();
    const { getSizeName } = useSizeDisplay();

    const estimatedDeliveryTimeInDays = computed(() => {
      const minDeliveryTime =
        props.row?.shippingInterval?.minimumDeliveryTimeInDays || 0;
      const maxDeliveryTime =
        props.row?.shippingInterval?.maximumDeliveryTimeInDays || 0;

      if (!maxDeliveryTime) {
        return '';
      }

      return websiteText('quick_cart__estimated_delivery_time_days', {
        min: minDeliveryTime,
        max: maxDeliveryTime,
      }).value;
    });

    return {
      isCartOpen,
      setQuantityOnRow,
      websiteText,
      estimatedDeliveryTimeInDays,
      getSizeName,
    };
  },
  methods: {
    closeCart() {
      this.isCartOpen = false;
    },
    async onRemove() {
      await this.setQuantityOnRow(this.row.id, 0);
    },
    async onChange($event) {
      const quantity = $event.target.value;
      // eslint-disable-next-line eqeqeq
      if (quantity == this.websiteText('more').value) {
        this.showNumericInput = true;
      } else {
        await this.setQuantityOnRow(this.row.id, quantity);
        this.$nextTick(() => {
          this.quantity = this.row.quantity;
        });
      }
    },
    async onSaveQuantity() {
      if (!this.isInputValid()) {
        this.inputQuantity = '';
        return;
      }
      await this.setQuantityOnRow(this.row.id, this.inputQuantity);
      this.$nextTick(() => {
        this.quantity = this.row.quantity;
      });
      this.showNumericInput = false;
      this.inputQuantity = '';
    },
    isInputValid() {
      const value = parseInt(this.inputQuantity);
      return !isNaN(value) && value > 0;
    },
    getRowImage(row) {
      return this.$toLitiumMediaUrl(row.imageId);
    },
    getQuantities(row) {
      const quantities = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

      if (row.quantity > 10) {
        quantities.push(row.quantity);
      }

      quantities.push(this.websiteText('more').value);

      return quantities;
    },
    formattedPrice() {
      const price = this.row?.unitCampaignPrice?.price
        ? this.row.unitListPrice * this.row.quantity
        : this.row.unitPrice * this.row.quantity;
      if (!price) return null;
      return this.$formatPrice(price);
    },
    formattedCampaignPrice() {
      const campaignPrice = this.row?.unitCampaignPrice?.price
        ? this.row?.unitCampaignPrice?.price * this.row.quantity
        : null;
      if (!campaignPrice) return null;
      return this.$formatPrice(campaignPrice);
    },
    // keeping this computed in case they want to display names again
    campaignName() {
      return this.row?.campaignName || this.row?.unitCampaignPrice?.name || '';
    },
  },
};
</script>

<template>
  <li class="quick-cart-row">
    <div class="quick-cart-row_thumbnail-container">
      <router-link class="quick-cart-row_thumbnail-link" @click="closeCart()" :to="row.url">
        <div class="quick-cart-row_thumbnail">
          <img
            class="quick-cart-row_thumbnail-image"
            :src="getRowImage(row)"
            :alt="row.variantName"
            v-if="row.isModelImage"
          />
          <img
            class="quick-cart-row_thumbnail-image quick-cart-row_thumbnail-image-contain"
            :src="getRowImage(row)"
            :alt="row.variantName"
            v-if="!row.isModelImage"
          />
        </div>
      </router-link>
    </div>

    <div class="quick-cart-row_details">
      <div>
        <div class="quick-cart-row_product-name-price">
          <div class="quick-cart-row_product-name-container">
            <div class="quick-cart-row_product-name">{{ row.variantName }}</div>
            <div class="quick-cart-row_color-size">
              {{ row.color }}, {{ getSizeName(row.size) }}
            </div>
            <div
              class="quick-cart-row_shipping-info"
              v-html="estimatedDeliveryTimeInDays"
            ></div>
          </div>
          <div class="quick-cart-row_product-price-container">
            <div
              class="quick-cart-row_product-price"
              :class="{
                'quick-cart-row_product-price--disabled':
                  formattedCampaignPrice(),
              }"
            >
              {{ formattedPrice() }}
            </div>
            <div class="quick-cart-row_product-campaign-price">
              {{ formattedCampaignPrice() }}
            </div>
            <!--             <div class="quick-cart-row_product-campaign-name">{{ campaignName() }}</div> -->
          </div>
        </div>
      </div>

      <div class="quick-cart-row_quantity-container">
        <div class="quick-cart-row_quantity" v-if="!showNumericInput">
          <select
            class="quick-cart-row_quantity-field"
            @change="onChange($event)"
            v-model="quantity"
          >
            <option
              v-for="q in getQuantities(row)"
              :label="q"
              :value="q"
              :selected="row.quantity == q"
            >
              {{ q }}
            </option>
          </select>
        </div>

        <div
          class="quick-cart-row_quantity-numeric__container"
          v-if="showNumericInput"
        >
          <input
            type="number"
            v-model="inputQuantity"
            class="quick-cart-row_quantity-numeric"
            data-cy="quickcart-quantity-numeric"
          />
          <a
            @click="onSaveQuantity()"
            class="quick-cart-row_quantity-save"
            data-cy="quickcart-quantity-save"
          >
            {{ websiteText('ok').value || 'ok' }}
          </a>
        </div>

        <button
          class="quick-cart-row_delete clean-button"
          @click="onRemove()"
          data-cy="quickcart-remove"
        >
          {{ websiteText('removelinktext').value || 'removelinktext' }}
        </button>
      </div>
    </div>
  </li>
</template>

<style>
.quick-cart-row {
  display: flex;
  align-items: stretch;
  padding: 20px 0;
  position: relative;
  border-bottom: 1px solid var(--color-grey900);
}

.quick-cart-row_thumbnail-container {
  position: relative;
  width: 30%;
  max-width: 130px;
}

.quick-cart-row_thumbnail-container:has(.quick-cart-row_thumbnail-link:focus-visible) {
  outline: 1px solid black;
  outline-offset: -1px;
}

.quick-cart-row_thumbnail {
  padding-top: 133%;
}

.quick-cart-row_thumbnail-image {
  object-fit: cover;
  object-position: 0 10%;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.quick-cart-row_thumbnail-image-contain {
  object-fit: contain;
  object-position: 50%;
}

.quick-cart-row_details {
  width: 100%;
  padding: 0 0 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.quick-cart-row_product-name-price {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.quick-cart-row_product-name-container {
  line-height: 24px;
}

.quick-cart-row_product-name {
  font-family: var(--font-body);
  color: var(--color-coldGrey200);
  display: inline-block;
}

.quick-cart-row_product-price-container {
  text-align: right;
  line-height: 24px;
}

.quick-cart-row_product-price {
  font-family: var(--font-proxima-nova-semi-bold);
}

.quick-cart-row_product-price--disabled {
  font-family: var(--font-body);
  text-decoration: line-through;
}

.quick-cart-row_product-campaign-price {
  color: var(--color-discount);
  font-family: var(--font-body);
}

.quick-cart-row_product-campaign-name {
  font-size: 13px;
}

.quick-cart-row_color-size {
  font-family: var(--font-body);
  color: var(--color-grey450);
  font-size: 14px;
}

.quick-cart-row_shipping-info {
  font-size: 13px;
  margin: 0 -5rem 0 0;
}

.quick-cart-row_quantity-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
}

.quick-cart-row_quantity-field {
  font-family: var(--font-body);
  background-image: url(/icons/icon_downarrow.svg);
  background-position: 90%;
  background-size: 14px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 38px;
  width: 76px;
  margin: 0;
  padding: 4px 4px 4px 12px;
  background-color: var(--color-grey990);
}

select.quick-cart-row_quantity-field:hover {
  background-color: var(--color-grey980);
}

.quick-cart-row_quantity-numeric__container {
  display: flex;
}

input[type='number'].quick-cart-row_quantity-numeric {
  font-family: var(--font-body);
  -webkit-appearance: none;
  -moz-appearance: textfield;
  height: 38px;
  width: 76px;
  margin: 0;
  text-align: right;
}

.quick-cart-row_delete {
  display: inline-block;
  align-self: flex-end;
  border-bottom: 1px solid var(--color-black);
  margin-top: 10px;
  text-decoration: underline;
}

.quick-cart-row_delete:hover,
.quick-cart-row_delete:focus {
  color: var(--color-coldGrey400);
  border-color: var(--color-coldGrey400);
}

.quick-cart-row_quantity-save {
  padding: 9px 10px;
  font-family: var(--font-header);
  font-size: 16px;
  background: var(--color-black);
  color: var(--color-white);
  text-transform: uppercase;
  text-align: center;
  border-radius: 2px;
  margin-left: 5px;
}

.quick-cart-row_quantity-save:hover {
  color: var(--color-white);
}

@media (--tabletAndDesktop) {
  .quick-cart-row_color-size {
    color: var(--color-coldGrey200);
  }

  .quick-cart-row_details {
    width: 70%;
  }
}
</style>
